import React from "react"
import styled from "styled-components"

import AboutUs1 from "../../static/aboutus/aboutus1.png"
import AboutUs1_480 from "../../static/aboutus/aboutus1-480.png"

const Container = styled.div`
border-bottom: solid 1px #707070;
  .aboutUs1-first-img img {
    width: 100vw;
  }

  .aboutUs1-text-part {
    margin: 0 auto;
    width: 60vw;
  }

  .aboutUs1-text-part p:last-child {
    font-size: 1.4vw;
    line-height: 1.5;
    color:#212121;
    font-weight: 400;
  }

  .aboutUs1-title {
    color: #d96e62;
    font-weight: 900;
    font-size: 1.7vw;
    line-height: 1.5;
    margin-top: 2vw;
  }

  .aboutUs1-sami-title {
    font-size: 2.1vw;
    line-height: 1.5;
    margin: 0.3vw 0 1.7vw;
    font-weight:500;
    color: #000000;
  }

  

  .aboutUs1-circle-item {
    display: flex;
    width 60vw;
    margin: 4vw auto 4vw;
    padding: 0 3vw;
    justify-content: space-between;
  }

  .aboutUs1-circle-item div{
    width: 15vw;
    height: 15vw;
    background-color: #fff;
    border-radius: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px lightgrey;
  }

  .aboutUs1-circle-item p{
    font-size: 1.4vw;
    text-align: center;
    font-weight:bold;
    line-height: 1.5;
  }

  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }


  @media screen and (max-width: 480px) {
    border-bottom: dotted 2px #707070;
    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }
    
    .aboutUs1-text-part {
      width: 80vw;
    }

    .aboutUs1-title {
      font-weight: bold;
      font-size: 4.3vw;
      line-height: 0.94;
      margin-top: 9.5vw;
    }

    .aboutUs1-sami-title {
      font-size: 6.5vw;
      line-height: 1.25;
      font-weight: bold;
      margin: 3vw 0 5vw;
    }

    .aboutUs1-text-part p:last-child span {
      font-weight: bold;
    }

    .aboutUs1-text-part p:last-child {
      font-size: 4.2vw;
      line-height: 1.47;
    }

    .aboutUs1-circle-item {
      width:80vw;
      flex-flow: row wrap;
      padding:0;
      margin: 7vw auto;
    }

    .aboutUs1-circle-item div {
      width: 38.5vw;
      height: 12vw;
      margin-bottom: 3.5vw;
      border-radius: 4vw;
      box-shadow: 0px 0px 20px lightgrey;
    }

    .aboutUs1-circle-item p {
      font-size: 4vw;
    }
  }
`

const aboutus1 = () => {
  return (
    <Container>
      <div className="aboutUs1-first-img">
        <img className="hide-480" src={AboutUs1} alt="aboutus 첫번째 이미지" />
        <img
          className="show-480"
          src={AboutUs1_480}
          alt="aboutus 첫번째 이미지"
        />
      </div>
      <div className="aboutUs1-text-part">
        <p className="aboutUs1-title">공간샘 소개</p>
        <p className="aboutUs1-sami-title">
          선생님의 삶에서, <br />
          사업가의 삶으로
        </p>
        <p>
          퇴근 후 공부할 곳이 없었어요 카페는 11시에
          <br className="show-480" /> 문을 닫고 낮에 가면 시끄럽고{" "}
          <br className="hide-480" />
          작은 카페는
          <br className="show-480" /> 사장님 눈치보이고... 그렇게 전전한 카페만
          50곳.
          <br />
          <span>
            “학습자를 위한 공간이 없구나.” <br className="show-480" />
            그들을 위한 ‘스터디 카페’를 만들자.
          </span>
          <br />
          '공간샘 스터디카페'는 그렇게 탄생했습니다.
        </p>
      </div>
      <div className="aboutUs1-circle-item">
        <div>
          <p>
            교육과 기술의 결합,
            <br />
            에듀테크
          </p>
        </div>

        <div>
          <p>O2O 교육환경</p>
        </div>

        <div>
          <p>함께 성장하는 곳</p>
        </div>
      </div>
    </Container>
  )
}

export default aboutus1
