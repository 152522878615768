import React, { useState } from "react"
import styled from "styled-components"
import Modal from "react-bootstrap/Modal"
import Popup_ans from "../../pages/popup-answer"
import "../../style/modal.css"
import AboutUs6 from "../../static/aboutus/aboutus6.png"
import AboutUs6_480 from "../../static/aboutus/aboutus6-480.png"

const Container = styled.div`
  .aboutUs6-text-part {
    position: absolute;
    width: 100vw;
    margin-top: 6vw;
  }

  .aboutUs6-text-part p:first-child {
    font-family: 'Spoqa Han Sans Neo', 'Spoqa Han Sans JP', sans-serif;
    font-weight: 900;
    font-size: 3vw;
    text-align: center;
    color: #fff;
    line-height: 2;
  }

  .aboutUs6-text-part p:nth-child(2) {
    font-size: 1.4vw;
    color: #fff;
    line-height: 1.75;
    text-align: center;
  }

  .aboutUs6-img-part img {
    width: 100vw;
    display: block;
  }

  .answer-detail-btn {
    display: block;
    margin: 1.5vw auto 0;
    border: none;
    border-radius: 100px;
    background-color: white;
    padding: 1vw 2vw 0.7vw;
    font-size: 1vw;
    font-weight: bold;
  }
  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none !important;
  }

  @media screen and (max-width: 480px) {
    .hide-480 {
      display: none !important;
    }

    .show-480 {
      display: block !important;
    }

    .aboutUs6-text-part {
      margin-top: 8vw;
    }

    .aboutUs6-text-part p:first-child {
      font-size: 7vw;
      line-height: 1.23;
    }

    .aboutUs6-text-part p:nth-child(2) {
      font-size: 4vw;
      line-height: 1.38;
      margin: 3vw auto;
    }

    .answer-detail-btn {
      padding: 2.4vw 6vw 1.7vw;
      font-size: 4vw;
      margin: 4vw auto 0;
    }
  }
`

const aboutus6 = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <Container>
      <div className="aboutUs6-part">
        <div className="aboutUs6-text-part">
          <p>
            기술력으로 앞서가는
            <br className="show-480" /> 공간샘과 함께하세요.
          </p>
          <p>
            지금 바로 무료 상담 받고 신규가맹, 업종변경{" "}
            <br className="show-480" />
            상관없이 특별한 창업혜택을 받아보세요
          </p>
          <button
            className="answer-detail-btn hide-480"
            onClick={() => window.open("/franchise", "_self")}
          >
            가맹문의 바로가기
          </button>
          <button className="answer-detail-btn show-480" onClick={handleShow}>
            가맹문의 바로가기
          </button>
        </div>

        <div className="aboutUs6-img-part">
          <img className="hide-480" src={AboutUs6} alt="배경이미지" />
          <img className="show-480" src={AboutUs6_480} alt="배경이미지" />
        </div>
      </div>
      <Modal className="answer-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <Popup_ans />
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default aboutus6
