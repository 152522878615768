import React from "react"
import Layout from "../components/Layout/Layout"
import styled from "styled-components"

import AboutUs1 from "../components/aboutUs/aboutus1"
import AboutUs2 from "../components/aboutUs/aboutus2"
import AboutUs3 from "../components/aboutUs/aboutus3"
import AboutUs4 from "../components/aboutUs/aboutus4"
import AboutUs5 from "../components/aboutUs/aboutus5"
import AboutUs6 from "../components/aboutUs/aboutus6"

const Container = styled.div`
  overflow-x: hidden;
`
const aboutUsPage = () => {
  return (
    <Container>
      <Layout>
        <AboutUs1 />
        <AboutUs2 />
        <AboutUs3 />
        <AboutUs4 />
        <AboutUs5 />
        <AboutUs6 />
      </Layout>
    </Container>
  )
}

export default aboutUsPage
