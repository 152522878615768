import React from "react"
import styled from "styled-components"
import AboutUs5 from "../../static/aboutus/aboutus5.png"
import AboutUs5_480 from "../../static/aboutus/aboutus5-480.png"
const Container = styled.div`
  padding: 0 0 4vw;

  .aboutUs5-text-part {
    margin-left: 20vw;
  }

  .aboutUs5-title {
    margin-top: 6vw;
    color: #d96e62;
    font-weight: 900;
    font-size: 1.7vw;
    line-height: 1.5;
  }

  .aboutUs5-sami-title {
    font-size: 2.1vw;
    line-height: 1.5;
    margin: 0.6vw 0;
    font-weight: 500;
  }

  .aboutUs5-img-part img {
    width: 100vw;
  }


  .aboutUs5-circle-item {
    display: flex;
    width 60vw;
    margin: 4vw auto 3vw;
    padding: 0 3vw;
    justify-content: space-between;
  }

  .aboutUs5-circle-item div{
    width: 15vw;
    height: 15vw;
    background-color: #fff;
    border-radius: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px lightgrey;
  }

  .aboutUs5-circle-item p{
    font-size: 1.4vw;
    text-align: center;
    font-weight:bold;
    line-height: 1.5;
  }

  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    padding: 8vw 0 0vw;
    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    .aboutUs5-part {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    .aboutUs5-text-part {
      width: 80vw;
      margin-left: 10vw;
    }

    .aboutUs5-title {
      font-weight: bold;
      font-size: 4.3vw;
      line-height: 0.94;
      margin-top: 9.5vw;
    }

    .aboutUs5-sami-title {
      font-size: 6.5vw;
      line-height: 1.25;
      font-weight: bold;
      margin: 3vw 0 5vw;
    }

    .aboutUs5-sami-title-480 {
      font-size: 4.2vw;
      font-weight: bold;
      line-height: 1.44;
    }

    .aboutUs5-circle-item {
      flex-direction: column;
      width: 100vw;
      margin: 6vw auto;
    align-items: center;
    }

    .aboutUs5-circle-item p {
      font-size: 5vw;
    }

    .aboutUs5-circle-item div {
      width: 65vw;
      height: 15vw;
      margin-bottom: 4vw;
      border-radius: 4vw;
      box-shadow: 0px 0px 20px lightgrey;
    }
  }

`

const aboutus5 = () => {
  return (
    <Container>
      <div className="aboutUs5-part">
        <div className="aboutUs5-img-part">
          <img className="hide-480" src={AboutUs5} alt="배경" />
          <img className="show-480" src={AboutUs5_480} alt="배경" />
        </div>

        <div className="aboutUs5-text-part">
          <p className="aboutUs5-title">공간샘 비전</p>
          <p className="aboutUs5-sami-title">
            공간샘은 에듀테크 <br className="show-480" />
            서비스의
            <br className="hide-480" />
            최앞단을 <br className="show-480" />
            걷고 있습니다
          </p>
        </div>

        <div className="aboutUs5-circle-item">
          <div>
            <p>
              AI,클라우드 기반
              <br className="hide-480" />
              학습관리
            </p>
          </div>
          <div>
            <p>
              온/오프라인
              <br className="hide-480" />
              학습공유
            </p>
          </div>

          <div>
            <p>
              성장을 돕는
              <br className="hide-480" />
              교육추천 플랫폼
            </p>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default aboutus5
