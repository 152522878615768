import React from "react"
import styled from "styled-components"
import { Fade, Bounce } from "react-awesome-reveal"

import AboutUs4 from "../../static/aboutus/aboutus4.png"

const Container = styled.div`
  padding: 6vw 0 0;

  .aboutUs4-part {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  .aboutUs4-text-part {
    margin-left: 20vw;
  }

  .aboutUs4-text-part p:last-child {
    font-size: 1.4vw;
    line-height: 1.5;
    color: #212121;
  }

  .aboutUs4-title {
    color: #d96e62;
    font-weight: 900;
    font-size: 1.7vw;
    line-height: 1.5;
  }

  .aboutUs4-sami-title {
    font-size: 2.1vw;
    line-height: 1.5;
    margin: 0.3vw 0 1.7vw;
    font-weight: 500;
  }

  .aboutUs4-img-part img {
    width: 45vw;
    display: block;
    margin-right: 10vw;
  }

  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    padding: 8vw 0 6vw;

    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    .aboutUs4-part {
      flex-direction: column;
      align-items: flex-start;
      width: 80vw;
    }
    .aboutUs4-text-part {
      width: 80vw;
      margin-left: 0;
    }

    .aboutUs4-title {
      font-weight: bold;
      font-size: 4.3vw;
      line-height: 0.94;
      margin-top: 1.5vw;
    }

    .aboutUs4-sami-title {
      font-size: 6.5vw;
      line-height: 1.25;
      font-weight: bold;
      margin: 3vw 0 5vw;
    }

    .aboutUs4-img-part img {
      width: 92vw;
      margin-left: -6vw;
      margin-right: 0;
      margin-bottom: 5vw;
    }

    .aboutUs4-sami-title-480 {
      font-size: 4.2vw;
      font-weight: bold;
      line-height: 1.44;
    }
  }
`

const aboutus4 = () => {
  return (
    <Container>
      <div className="aboutUs4-part">
        <div className="aboutUs4-text-part">
          <p className="aboutUs4-title">컨텐츠 연구소</p>
          <p className="aboutUs4-sami-title">
            공간에 <br className="show-480" />
            콘텐츠를 더하다
          </p>
          <p className="hide-480">
            폭넓은 인기 교육콘텐츠로
            <br />
            학습자의 만족도를 <br />
            더욱 끌어올렸습니다.
          </p>
        </div>
        <div className="aboutUs4-img-part">
          <img src={AboutUs4} alt="키오스크" />
        </div>

        <p className="aboutUs4-sami-title-480 show-480">
          폭넓은 인기 교육콘텐츠로
          <br />
          학습자의 만족도를 더욱 끌어올렸습니다.
        </p>
      </div>
    </Container>
  )
}

export default aboutus4
