import React from "react"
import styled from "styled-components"

import AboutUs2 from "../../static/aboutus/aboutus2.png"

const Container = styled.div`
  padding: 6vw 0 8vw;
  border-bottom: solid 1px #707070;
  .aboutUs2-part {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  .aboutUs2-text-part {
    margin-left: 20vw;
  }

  .aboutUs2-text-part p:last-child {
    font-size: 1.4vw;
    line-height: 1.5;
    color: #212121;
  }

  .aboutUs2-title {
    color: #d96e62;
    font-weight: 900;
    font-size: 1.7vw;
    line-height: 1.5;
  }

  .aboutUs2-sami-title {
    font-size: 2.1vw;
    line-height: 1.5;
    margin: 0.3vw 0 1.7vw;
    font-weight: 500;
  }

  .aboutUs2-img-part img {
    width: 40vw;
    margin-right: 15vw;
  }
  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    border-bottom: dotted 2px #707070;
    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    .aboutUs2-part {
      flex-direction: column;
      align-items: flex-start;
      width: 80vw;
    }
    .aboutUs2-text-part {
      width: 80vw;
      margin-left: 0;
    }

    .aboutUs2-title {
      font-weight: bold;
      font-size: 4.3vw;
      line-height: 0.94;
      margin-top: 1.5vw;
    }

    .aboutUs2-sami-title {
      font-size: 6.5vw;
      line-height: 1.25;
      font-weight: bold;
      margin: 3vw 0 5vw;
    }

    .aboutUs2-img-part img {
      width: 80vw;
      margin-right: 0;
    }

    .aboutUs2-sami-title-480 {
      font-size: 4.2vw;
      font-weight: bold;
      line-height: 1.44;
    }
  }
`

const aboutus2 = () => {
  return (
    <Container>
      <div className="aboutUs2-part">
        <div className="aboutUs2-text-part">
          <p className="aboutUs2-title">공간 연구소</p>
          <p className="aboutUs2-sami-title">
            학습과 휴식이 <br />
            공존하는 학습공간
          </p>
          <p className="hide-480">
            특허 받은 방음기술과
            <br />
            친환경 인테리어
            <br />
            다양한 학습자를 고려한
            <br />
            공간샘만의 프리미엄 학습공간
          </p>
        </div>

        <div className="aboutUs2-img-part">
          <img src={AboutUs2} alt="포름알데히드" />
        </div>
        <p className="aboutUs2-sami-title-480 show-480 ">
          특허 받은 방음기술과
          <br />
          친환경 인테리어
          <br />
          다양한 학습자를 고려한
          <br />
          공간샘만의 프리미엄 학습공간
        </p>
      </div>
    </Container>
  )
}

export default aboutus2
