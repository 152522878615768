import React from "react"
import styled from "styled-components"

import AboutUs3 from "../../static/aboutus/aboutus3.png"
import AboutUs3_480 from "../../static/aboutus/aboutus3-480.png"

const Container = styled.div`
  padding: 2vw 0 1vw;
  border-bottom: solid 1px #707070;
  .aboutUs3-part {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  .aboutUs3-text-part {
    margin-left: 20vw;
  }

  .aboutUs3-text-part p:last-child {
    font-size: 1.4vw;
    line-height: 1.5;
    color: #212121;
  }

  .aboutUs3-title {
    color: #d96e62;
    font-weight: 900;
    font-size: 1.7vw;
    line-height: 1.5;
    margin-top: -2vw;
  }

  .aboutUs3-sami-title {
    font-size: 2.1vw;
    line-height: 1.5;
    margin: 0.3vw 0 1.7vw;
    font-weight: 500;
  }

  .aboutUs3-img-part img {
    width: 35vw;
    margin-right: 18vw;
  }

  .hide-480 {
    display: block;
  }

  .show-480 {
    display: none;
  }

  @media screen and (max-width: 480px) {
    padding: 8vw 0 8vw;
    border-bottom: dotted 2px #707070;
    .hide-480 {
      display: none;
    }

    .show-480 {
      display: block;
    }

    .aboutUs3-part {
      flex-direction: column;
      align-items: flex-start;
      width: 80vw;
    }
    .aboutUs3-text-part {
      width: 80vw;
      margin-left: 0;
    }

    .aboutUs3-title {
      font-weight: bold;
      font-size: 4.3vw;
      line-height: 0.94;
      margin-top: 1.5vw;
    }

    .aboutUs3-sami-title {
      font-size: 6.5vw;
      line-height: 1.25;
      font-weight: bold;
      margin: 3vw 0 5vw;
    }

    .aboutUs3-img-part img {
      width: 80vw;
      margin-right: 0;
    }

    .aboutUs3-sami-title-480 {
      font-size: 4.2vw;
      font-weight: bold;
      line-height: 1.44;
    }
  }
`

const aboutus3 = () => {
  return (
    <Container>
      <div className="aboutUs3-part">
        <div className="aboutUs3-text-part">
          <p className="aboutUs3-title">무인 연구소</p>
          <p className="aboutUs3-sami-title">
            매장 알바생 대신 <br />
            양방향 키오스크
          </p>
          <p className="hide-480">
            매장 고객 데이터 분석은
            <br />
            클라우드 시스템으로.
            <br />
            학습자도 편안하게,
            <br />
            관리자도 만족스럽게
          </p>
        </div>

        <div className="aboutUs3-img-part">
          <img className="hide-480" src={AboutUs3} alt="키오스크" />
          <img className="show-480" src={AboutUs3_480} alt="키오스크" />
        </div>

        <p className="aboutUs3-sami-title-480 show-480">
          매장 고객 데이터 분석은
          <br />
          클라우드 시스템으로.
          <br />
          학습자도 편안하게, 관리자도 만족스럽게
        </p>
      </div>
    </Container>
  )
}

export default aboutus3
